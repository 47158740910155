import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import SocialIcons from '../components/social/SocialIcons';
import ContactForm from '../components/forms/ContactForm';

const StyledArticle = styled.article`
	.main-section {
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		padding-top: 15rem;
		padding-bottom: 20rem;

		@media (max-width: 768px) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
	}

	.sub-head {
		font-size: 2rem;
		font-weight: ${(props) => props.theme.fonts.weights.bold};
		text-transform: uppercase;
		letter-spacing: 4.2px;
		margin-top: 2rem;
	}

	.big-n-gold {
		font-size: 3rem;
		font-weight: ${(props) => props.theme.fonts.weights.bold};
		color: ${(props) => props.theme.colors.tertiary};
		text-transform: uppercase;
		letter-spacing: 4.2px;
		margin-top: 2rem;
	}

	.details {
		margin-top: 3rem;
	}

	.contact-deets {
		margin-top: 2rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		li {
			margin: 0 0.75rem;

			a {
				font-size: 2.1rem;
				font-weight: ${(props) => props.theme.fonts.weights.bold};
				letter-spacing: 2.64px;
				color: ${(props) => props.theme.colors.primary};
				transition: 300ms;

				svg {
					path,
					circle {
						fill: ${(props) => props.theme.colors.primary};
					}
				}

				&:hover {
					color: ${(props) => props.theme.colors.tertiary};

					svg {
						transform: scale(1.1);

						path,
						circle {
							fill: ${(props) => props.theme.colors.tertiary};
						}
					}
				}
			}
		}
	}

	[class*='ContactForm'] {
		margin-top: 7rem;
	}

	.map-section {
		padding-top: 18rem;
		padding-bottom: 15rem;
		background-size: cover;
		background-position: center bottom;
		background-repeat: no-repeat;

		@media (max-width: 1087px) {
			padding-top: 5rem;
			padding-bottom: 25rem;
			background-position: 65% bottom;
		}

		.contact-details {
			background-color: ${(props) => props.theme.colors.lightest};
			padding: 4.5rem 3rem;

			@media (max-width: 768px) {
				padding: 3rem 1.5rem;
			}

			h2 {
				font-family: ${(props) => props.theme.fonts.secondary};
				font-weight: ${(props) => props.theme.fonts.weights.regular};
				font-size: 3.5rem;
				letter-spacing: 0.36px;
				text-transform: none;

				@media (max-width: 768px) {
					font-size: 2rem;
				}
			}

			address {
				font-size: 2.5rem;
				font-style: normal;
				font-weight: ${(props) => props.theme.fonts.weights.regular};
				margin-top: 2rem;

				@media (max-width: 768px) {
					font-size: 2.1rem;
				}
			}

			ul {
				margin-top: 3rem;

				li {
					a {
						font-weight: ${(props) => props.theme.fonts.weights.bold};
						font-size: 2.1rem;
						letter-spacing: 0.12em;
						text-transform: uppercase;
						color: ${(props) => props.theme.colors.primary};
						transition: 300ms;

						&:hover {
							text-decoration: underline;
							color: ${(props) => props.theme.colors.tertiary};
						}

						@media (max-width: 499px) {
							font-size: 4vw;
						}

						@media (min-width: 500px) and (max-width: 768px) {
							font-size: 1.8rem;
						}
					}
				}
			}

			hr {
				background-color: ${(props) => props.theme.colors.quaternary};
			}

			.directions {
				font-family: ${(props) => props.theme.fonts.secondary};
				font-size: 2.5rem;
				color: ${(props) => props.theme.colors.tertiary};
				letter-spacing: 0.025em;
				position: relative;

				&::before,
				&::after {
					transition: 500ms cubic-bezier(0.12, -1.05, 0.72, 0.9);
					display: inline-block;
					position: absolute;
				}

				&::before {
					content: '';
					height: 0.1rem;
					width: 2.1rem;
					right: -4rem;
					top: 50%;
					transform: translateY(-50%);
					background-color: ${(props) => props.theme.colors.tertiary};
				}

				&::after {
					content: '';
					height: 1.5rem;
					width: 1.5rem;
					right: -3.9rem;
					top: 50%;
					transform: translateY(-50%) rotate(-45deg);
					border-right: 1px solid;
					border-bottom: 1px solid;
					border-right-color: ${(props) => props.theme.colors.tertiary};
					border-bottom-color: ${(props) => props.theme.colors.tertiary};
				}

				&:hover {
					&::before {
						transform: translateY(-50%) translateX(1rem);
					}

					&::after {
						transform: translateY(-50%) translateX(1rem) rotate(-45deg);
					}
				}
			}
		}
	}
`;

export const ContactPageTemplate = ({ title, background, mapBackground }) => (
	<StyledArticle>
		<section
			className="section main-section"
			style={{
				backgroundImage: `url(${background.childImageSharp.fluid.src})`,
			}}
		>
			<div className="container">
				<div className="columns is-centered">
					<div className="column is-8 has-text-centered">
						<h1>Contact Us</h1>
						<AccentLine align="center" />
						<p className="sub-head">
							Need some more information? Have a general inquiry?
						</p>
						<p className="big-n-gold">We’re here to help.</p>
						<p className="details">
							You will always hear back from us within 24hrs of submitting your
							form. If you are looking to talk to someone directly or would like
							an immediate response, please give us a call.
						</p>
						<ul className="contact-deets">
							<li>
								<SocialIcons />
							</li>
							<li>
								<a href="tel:+17057229111">705-722-9111</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="columns is-8 is-centered">
					<div className="column">
						<ContactForm />
					</div>
				</div>
			</div>
		</section>
		<section
			className="section map-section"
			style={{
				backgroundImage: `url(${mapBackground.childImageSharp.fluid.src})`,
			}}
		>
			<div className="container">
				<div className="columns">
					<div className="column is-5-widescreen is-half-desktop is-12-tablet is-12-mobile">
						<div className="contact-details has-text-centered">
							<h2>Parker Coulter Real Estate</h2>
							<AccentLine align="center" />
							<address>
								360 Shanty Bay Road <br />
								Barrie, Ontario <br />
								L4M1E7
							</address>
							<ul>
								<li>
									<a href="mailto:contact@parkercoulter.com">
										contact@parkercoulter.com
									</a>
								</li>
								<li>
									<a href="tel:+17057229111">705-722-9111</a>
								</li>
							</ul>
							<hr />
							<a
								className="directions"
								href="https://www.google.com/maps/place/Parker+Coulter+Realty+Brokerage+Inc./@44.3955378,-79.6411797,15z/data=!4m5!3m4!1s0x0:0xb64f324089cc7f40!8m2!3d44.3955378!4d-79.6411797"
								target="_blank"
								rel="noopener noreferrer"
							>
								Get Directions
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	</StyledArticle>
);

ContactPageTemplate.propTypes = {
	title: PropTypes.string,
};

const ContactPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout page="contact">
			<SEO
				title={frontmatter.title}
				description="We're here to help! Submit Your Inquiry Via Our Website Form For A Response Within 24hrs. If You Are Looking For Quicker Service, Connect Directly and Call 705-722-9111."
			/>
			<ContactPageTemplate
				title={frontmatter.title}
				background={frontmatter.main_bg}
				mapBackground={frontmatter.map_bg}
			/>
		</Layout>
	);
};

ContactPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ContactPage;

export const contactPageQuery = graphql`
	query ContactPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				main_bg {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
				map_bg {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
